import message from 'antd/lib/message';
import { Quill } from 'react-quill';
import QuillMention from 'quill-mention';

import { ERR_MESSAGE_DURATION, MEDIA_BASE_URL } from 'other';
import FetchMentions from './FetchMentions';
import { http, THttpResponse } from 'services';
import { store, TState } from 'store';
import { TFileDescription, TMedia } from 'types';

/**/
type FileResp = {
  id: number;
  media: TMedia;
};

/**
 *
 */
function lookup(text: string) {
  const {
    dictionaries: { companyStatuses, fishFarmStatuses, vesselStatuses }
  }: TState = store.getState();

  return new FetchMentions(
    companyStatuses,
    fishFarmStatuses,
    vesselStatuses
  ).find(text);
}

/**/
Quill.register('modules/mentions', QuillMention);
const TYPE_THRESHOLD = 3;

/**
 *
 */
export function getMentionModule(trialOption?: { id: string; value: string }) {
  return {
    allowedChars:
      /^[A-Za-z\sàáâäãåąčćęèéêëėįìíîïłńòóôöõøùúûüųūÿýżźñçšžÀÁÂÄÃÅĄĆČĖĘÈÉÊËÌÍÎÏĮŁŃÒÓÔÖÕØÙÚÛÜŲŪŸÝŻŹÑßÇŒÆŠŽ∂ð]*$/,
    mentionDenotationChars: ['@'],
    dataAttributes: [
      'denotationChar',
      'disabled',
      'id',
      'path',
      'type',
      'value'
    ],
    showDenotationChar: false,
    renderLoading: () => 'Loading...',
    /**/
    source: async function (searchTerm, renderList) {
      return searchTerm.length < TYPE_THRESHOLD
        ? trialOption && renderList([trialOption], searchTerm)
        : renderList(await lookup(searchTerm));
    }
  };
}

/**
 *
 * @param file
 * @param path
 * @param onSuccess
 */
export function uploadFile(
  file: File,
  path: string,
  onSuccess: (fileDescription: TFileDescription) => void
): void {
  const hide = message.loading('Uploading your file...', 0);
  const formData = new FormData();
  formData.append('file', file);

  http
    .send({
      body: formData,
      method: 'POST',
      url: path
    })
    .then(({ data }: THttpResponse<FileResp>) => {
      hide();
      onSuccess({
        id: data.id,
        name: file.name,
        src: `${MEDIA_BASE_URL}/${data.media.path}`
      });
    })
    .catch((e: Error) => {
      hide();
      message.error(e.message, ERR_MESSAGE_DURATION);
    });
}

/**
 *
 * @param path
 */
export function removeFile(path: string): void {
  http
    .send({
      method: 'DELETE',
      url: path
    })
    .catch((e: Error) => {
      message.error(e as any, ERR_MESSAGE_DURATION);
    });
}
